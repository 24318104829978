define("ember-videojs/components/videojs-player", ["exports", "ember-videojs/components/videojs-base", "ember-videojs/templates/components/videojs-player"], function (_exports, _videojsBase, _videojsPlayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _videojsBase.default.extend({
    layout: _videojsPlayer.default
  });
});