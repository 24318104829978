define("ember-videojs/templates/components/videojs-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EY3BwecH",
    "block": "[[[10,\"video\"],[14,0,\"video-js\"],[15,\"autoplay\",[36,1]],[15,\"controls\",[36,2]],[15,\"loop\",[36,3]],[15,\"muted\",[36,4]],[15,\"playsinline\",[36,5]],[14,\"preload\",\"auto\"],[15,\"poster\",[36,6]],[14,\"data-setup\",\"{}\"],[15,\"crossorigin\",[36,7]],[14,\"tabindex\",\"0\"],[12],[1,\"\\n\"],[41,[31,9],[[[1,\"    \"],[10,\"source\"],[15,\"src\",[36,9]],[15,4,[36,11]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[31,12],[[[42,[28,[37,14],[[28,[37,14],[[31,12]],null]],null],null,[[[1,\"      \"],[10,\"source\"],[15,\"src\",[30,1,[\"src\"]]],[15,4,[30,1,[\"type\"]]],[12],[13],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"\\n\"],[41,[31,15],[[[1,\"    \"],[10,\"track\"],[14,\"kind\",\"captions\"],[15,\"src\",[36,15]],[14,\"srclang\",\"en\"],[14,\"label\",\"English\"],[14,\"default\",\"\"],[14,4,\"text/vtt\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[31,17],[[[42,[28,[37,14],[[28,[37,14],[[31,17]],null]],null],null,[[[1,\"      \"],[10,\"track\"],[15,\"kind\",[30,2,[\"kind\"]]],[15,\"src\",[30,2,[\"src\"]]],[15,\"srclang\",[30,2,[\"language\"]]],[15,\"label\",[30,2,[\"label\"]]],[14,\"default\",\"\"],[15,4,[30,2,[\"type\"]]],[12],[13],[1,\"\\n\"]],[2]],null]],[]],null],[1,\"\\n  \"],[10,2],[14,0,\"vjs-no-js\"],[12],[1,\"\\n    To view this video please enable JavaScript, and consider upgrading to a web browser that\\n    \"],[10,3],[14,6,\"https://videojs.com/html5-video-support/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,\"supports HTML5 video\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\",\"textTrack\"],false,[\"video\",\"autoplay\",\"controls\",\"loop\",\"muted\",\"playsinline\",\"poster\",\"crossorigin\",\"if\",\"src\",\"source\",\"type\",\"sources\",\"each\",\"-track-array\",\"textTrack\",\"track\",\"textTracks\",\"p\",\"a\"]]",
    "moduleName": "ember-videojs/templates/components/videojs-player.hbs",
    "isStrictMode": false
  });
});